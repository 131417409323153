export const scrollToTop = () => window.scroll(0, 0)

export const scrollToRef = (ref) => scrollToNode(ref.current)

// this works in some cases where scrollIntoView() doesn't ¯\_(ツ)_/¯
export const scrollToNode = (node) =>
  window.scroll(0, (absTopOffset(node)))

const absTopOffset = (el) =>
  el.offsetParent ? el.offsetParent.offsetTop + absTopOffset(el.offsetParent) : 0
