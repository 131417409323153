import { debug } from 'shared'
import 'whatwg-fetch'

// Preferably use the `useJsonApi` webhook.
// Otherwise use `.call` for Promise/await based fetching.
// The other methods require a `success` callback.
export class JSONFetcher {
  static load({ url, success, signal = undefined }) {
    fetch(url, { signal: signal })
      .then(response => response.json())
      .then(json => success(json))
      .then(undefined, err => debug(err))
  }

  static post({ ...opts }) {
    this.submit({ method: 'post', ...opts })
  }

  static put({ ...opts }) {
    this.submit({ method: 'put', ...opts })
  }

  static delete({ ...opts }) {
    this.submit({ method: 'delete', ...opts })
  }

  static async call({ ...opts }) {
    return new Promise((resolve, reject) => {
      this.submit({ ...opts, success: resolve, onError: reject })
    })
  }

  // private
  static submit({ url, body, success, method = 'get', onError = null, signal = undefined }) {
    fetch(url, {
      method,
      signal,
      body: JSON.stringify(body),
      credentials: 'same-origin',
      headers: this.buildHeaders(),
    }).then(this.checkStatus)
      .then(response => response.json())
      .then(json => success(json))
      .then(undefined, err => {
        debug(err)
        onError && onError(err)
      })
  }

  static buildHeaders() {
    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
    return {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token,
    }
  }

  static async checkStatus(response) {
    if (response.ok || response.status === 409) return response
    else throw new Error(`JSONFetcher: ${response.status} ${response.statusText}`)
  }
}
