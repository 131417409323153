import { useState, useEffect } from 'react'
import {TwitterPicker} from 'react-color'

export const ColorInput = (props) => {
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState(props.control.value)

  useEffect(() => { props.control.value = value }, [value])

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      setVisible(false)
    }
  }

  const handleColorChange = (event) => {
    setValue(event.hex.replace('#', ''))
  }

  return <div className='input-group'>
    <div style={{
      background: `#${value} none repeat scroll 0% 0%`,
      height: 30,
      width: 30,
      cursor: 'pointer',
      position: 'relative',
      borderRadius: 4,
      margin: '0px 6px 6px 0px',
      border: '1px solid #ccc'
    }} onClick={() => setVisible(true)} title={`#${value}`} tabIndex={0} />

    {visible && <div style={popoverStyle} onKeyDown={handleEnter}>
      <div style={coverStyle} onClick={() => setVisible(false)} />
      <TwitterPicker colors={colors} color={value} onChangeComplete={handleColorChange} />
    </div>}
  </div>
}

const colors = [
  '#9ECB0A', '#3aa03a', '#2c6700', '#ffcc00', '#fd7222', '#cc0000', '#a50516',
  '#964b00', '#0099cc', '#336699', '#003366', '#a51497', '#6b28ce', '#999999',
  '#666666', '#333333',
]

const popoverStyle = {
  position: 'absolute',
  zIndex: 2,
  top: 40,
  left: -5,
}

const coverStyle = {
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
}
