export const ProjectPreview = () => {
  return <div className="generic-teaser-small is-preview">
            <div className='animated-background'>
              <div className='background-masker mask-picture' />
              <div className='background-masker mask-left-side' />
              <div className='background-masker mask-right-side' />
              <div className='background-masker mask-carrier' />
              <div className='background-masker mask-bottom' />
              <div className='background-masker mask-headline-1' />
              <div className='background-masker mask-headline-2' />
              <div className='background-masker mask-headline-3' />
              <div className='background-masker mask-progress' />
            </div>
          </div>
}

export const ProjectPreviewList = (props) => {
  return <>
    {[...Array(props.count || 3).keys()].map(
      (i) => <div className="col-md-8 mb-4" key={i}>
        <ProjectPreview />
      </div>
    )}
  </>
}
