import accounting from 'accounting'

var accounting_configuration = {};

accounting_configuration.de = {
  currency: {
    symbol: "\u20ac",
    format: "%v %s",
    decimal: ",",
    thousand: ".",
    precision: "2"
  },
  number: {
    precision: "2",
    decimal: ",",
    thousand: "."
  }
};

accounting_configuration.en = {
  currency: {
    symbol: "\u20ac",
    format: "%s%v",
    decimal: ".",
    thousand: ",",
    precision: "2"
  },
  number: {
    precision: "2",
    decimal: ".",
    thousand: ","
  }
};

const locale_config = accounting_configuration[document.querySelector('html').getAttribute('lang') || 'de']
if(locale_config) { accounting.settings = locale_config }

export const formatMoney = accounting.formatMoney

export function formatCents(cents, opts = {}) {
  opts.precision = (opts.precision === undefined) ? ((cents % 100 == 0) ? 0 : 2) : opts.precision
  return formatMoney(cents / 100, opts)
}

// Treat string amounts kind of language agnostic. Supports '.' or ',' as a
// delimiter for fractional digits. Use it in combination with `sanitizeMoney`.
export const parseCents = (sanitizedAmount) => {
  if (sanitizedAmount == '') return 0
  const floatText = sanitizedAmount.toString().replace(',', '.')
  const cents = parseFloat(floatText) || 0
  return Math.round(cents * 100)
}

// Force money containing string into a sensible shape: only one , or . is allowed
// as a delimiter. No seperator, no chars, no -, no bs
export const sanitizeMoney = (value) => {
  return value.
    replace(/[^\d.,]/g, '').                // drop anything except digits, dots and commas
    replace(/^[.,]+/, '').                  // drop any leading dot and comma
    replace(/^0{2,}/, '0').                 // limit to one leading 0
    replace(/[.,]([^.,]*)(?=[.,])/g, '$1'). // drop any dots and commas except the last one
    replace(/([.,]\d\d).*$/, '$1')          // limit to two decimal places
}
