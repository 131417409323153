import { useEffect, useState } from 'react'
import { JSONFetcher } from '../helpers/json_fetcher'

// Hook to use JSON APIs, e.g. api_v4.
// Changing the passed URL automatically triggers new requests and data updates.
export const useJsonApi = (url) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    url && JSONFetcher.load({
      url, success: (data) => {
        setData(data)
        setLoading(false)
      }
    })
  }, [url])

  return { data, loading }
}
