import { useState } from 'react'
import { DSIHtml, Modal } from 'shared'
import classNames from 'classnames'

export const TaxNotice = (props) => {
  const [showModal, setShowModal] = useState(false)
  const hideModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)

  return <>
    <DSIHtml as={props.as} value={I18n.t('shared.tax_deductibility_notice_html')} onClick={openModal} className={classNames(props.additionalClassName)} />
    <Modal
      id='thank-you-modal'
      onHide={hideModal}
      show={showModal}
      header={I18n.t('shared.tax_deductibility_modal_headline')}
    >
      <p>
        <DSIHtml value={I18n.t('shared.tax_deductibility_modal_html')} />
      </p>
      <div className='text-right'>
        <a className='btn btn-primary' onClick={hideModal}>{I18n.t('misc.close')}</a>
      </div>
    </Modal>
  </>
}
