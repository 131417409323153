import {ModalWithTrigger} from 'shared'

// Usage example (in slim)
//
// div data-behavior='modal-link' data-content-id='#unique-modal-id' data-trigger='Open Modal' data-title='Modal Title'
//  optionally: data-css-class, data-clicktracking-name
//
// div style='display: none'
//   #unique-modal-id content for the modal

export const ModalLink = (props) => {
  return <ModalWithTrigger {...props}>
    <div ref={ref => ref && ref.appendChild(document.querySelector(props.contentId).cloneNode(true))} />
  </ModalWithTrigger>
}
