import { useState } from 'react'
import classNames from 'classnames'
import { gaEvent, Modal, ShareButtons } from 'shared'

export const SharingGridButton = ({ shareUrl, gaLabel, platforms, modalHeader, buttonLabel, className }) => {
  const [show, setShow] = useState(false)

  const handleClick = () => {
    gaEvent('engagement', 'click sharing_modal_open')
    setShow(true)
    document.activeElement.blur()
  }

  const onHide = () => {
    gaEvent('engagement', 'click sharing_modal_close')
    setShow(false)
  }

  return <div className='w-100'>
    <button onClick={handleClick} className={classNames('btn btn-outline-green w-100', className)}>
      {buttonLabel}
    </button>

    <Modal
      show={show}
      onHide={onHide}
      content={<ModalContent shareUrl={shareUrl} gaLabel={gaLabel} platforms={platforms} />}
      header={modalHeader}
    />
  </div>
}

const ModalContent = ({ shareUrl, gaLabel, platforms }) => <>
  <p>{I18n.t('projects.show.stats.share_modal_text')}</p>
  <div className='projects-sharing-grid'>
    <ShareButtons
      project
      platforms={platforms}
      mobileOnly={['whatsapp', 'messenger']}
      shape='roundWithLabel'
      shareUrl={shareUrl}
      withLabel
      gaCategory='engagement'
      gaLabel={gaLabel}
    />
  </div>
</>
