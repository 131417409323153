import { dataLayer } from './safe_data_layer'

export class GtmHelper {
  static pushProjectImpressions(project, position, list) {
    const impressionData = {
      'event': 'eeProductImpression',
      'ecommerce': {
        'currencyCode': 'EUR',
        'impressions': projectList(project, position, list)
      }
    }
    dataLayer.push(impressionData)
  }

  static pushProjectClicks(project, position, list) {
    const clickData = {
      'event': 'eeProductClick',
      'ecommerce': {
        'click': {
          'actionField': { 'list': list },
          'products': projectList(project, position, list)
        }
      }
    }
    dataLayer.push(clickData)
  }

  static pushProductDetailImpression(project) {
    const detailData = {
      'event': 'eeProductDetailImpression',
      'ecommerce': {
        'detail': {
          'products': projectList(project)
        }
      }
    }
    dataLayer.push(detailData)
  }

  static pushAddToBasket(project) {
    const basketData = {
      'event': 'eeAddToBasket',
      'ecommerce': {
        'currencyCode': 'EUR',
        'add': {
          'products': projectList(project, null, null, 1)
        }
      }
    }
    dataLayer.push(basketData)
  }
}

function projectList(project, position, list, quantity) {
  const projectData = { name: project.title, id: `Project#${project.id}` }
  if (project.carrier) projectData.brand = `Organisation#${project.carrier.id} ${project.carrier.name}`
  if (quantity) projectData.quantity = quantity
  if (position) projectData.position = position
  if (list) projectData.list = list
  return [projectData]
}
