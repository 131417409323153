import {useEffect} from 'react'

export const StoreRecaptchaToken = ({sitekey, action, verifyCallback, submitCount}) => {
  useEffect(() => {
    window.grecaptcha && grecaptcha.ready(() => {
      grecaptcha.execute(sitekey, {action}).then(verifyCallback)
    })
  }, [submitCount])
  return null
}
