import classNames from 'classnames'

export const FormGroup = ({children, field, form, hint, groupClass, labelClass, ...props }) => {
  const error = fieldError({field, form})
  const inputClass = inputClassName({field, form, extra: props.className})

  return <div className={classNames('form-group', groupClass)}>
    <label className={classNames('control-label', labelClass)} htmlFor={field.name}>{props.label}</label>
    {children || <input id={field.name} className={inputClass} type='text' {...field} {...props} />}
    {error && <span className='help-inline'>{error}</span>}
    {hint && <small className='form-text text-muted'>{hint}</small>}
  </div>
}

export const inputClassName = ({field, form, extra = null}) =>
  classNames('form-control', { 'field-with-error': !!fieldError({field, form}) }, extra)

export const fieldError = ({field, form}) =>
  form.touched[field.name] && form.errors[field.name]
