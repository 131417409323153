import { useState, useLayoutEffect, useRef, useEffect } from 'react'
import {iframeResizer} from 'iframe-resizer'
import {QueryHelper} from 'shared'

export const Modal = (props) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.addEventListener('message', handleLoadingMessage)
    return () => {
      window.removeEventListener('message', handleLoadingMessage)
    }
  })

  const handleLoadingMessage = (event) => {
    if (event.data === 'bpIframeLoadStart') setLoading(false)
  }

  return <div role='dialog' style={{}}>
    <Backdrop onClick={props.onHide}/>
    <Canvas onClick={props.onHide}>
      {loading && <LogoSpinner />}
      <Iframe {...props.iframeForm} />
      <CloseButton />
    </Canvas>
  </div>
}

const Iframe = (props) => {
  const iframeRef = useRef(null)

  useLayoutEffect(() => {
    iframeResizer({checkOrigin: false}, iframeRef.current)
  })

  const params = QueryHelper.toQuery({
    color:                   props.color.replace('#', ''),
    donation_amount:         props.defaultAmount,
    default_payment_method:  props.defaultPaymentMethod,
    default_interval:        props.defaultInterval,
    bottom_logo:             'white',
    utm_campaign:            'external_donation_form',
    utm_source:              `domain:%20${props.referrer}`,
    utm_medium:              `${props.receiverType}_${props.receiverId}`,
    utm_content:             props.referrer,
    tracking_via:            'overlay_widget'
  })

  const src = `${props.domain}/${props.lang}/donate/iframe/${props.receiverType}/${props.receiverId}${params}`

  return <iframe
    ref={iframeRef}
    src={src}
    height={1200} // This is only for IE. iframeResizer will adjust it for reasonable browsers.
    width='100%'
    frameBorder={0}
    style={{
      maxWidth:   '600px',
      maxHeight:  'none',
      width:      '100%',
      margin:     '45px auto',
      display:    'block',
    }}
  />
}

const LogoSpinner = () => {
  return <>
    <style dangerouslySetInnerHTML={{__html: `@keyframes logo-animation{ to { stroke-dashoffset:20000 } }`}} />
    <svg style={{height: 100, margin: '45px auto', display: 'block'}} version="1.1" x="0px" y="0px" viewBox="0 0 3968.5 2834.6">
      <path style={{fill: 'none', stroke: '#FFFFFF', strokeWidth: 250, strokeMiterlimit: 100, strokeDasharray: 10000, animation: 'logo-animation 4s linear infinite', animationDirection: 'reverse'}} d="M2572.6,805.7c-33.3,35.8-199.5,207-217.4,226.3c-73-78.6-188.4-271.1-408.5-273.4
        c-134,0.2-240.6,50.9-322.5,132.1c-111.9,122.6-97.9,205.3-100,262.8c-4.4,118.6,49,187.5,196.6,348.5
        c241.3,261.1,487.4,543.4,558.9,619.9c69.9,74.7,60.5,82.1,130.3,7c66-71,137.1-131.2,238.6-241c344.8-372.9,412.6-763.5,298-1085.7
        c-108.2-304.1-512.5-637.1-967.4-637.3c-467.3,3.3-872.3,317.9-975.9,637.6c-69.6,214.8-95.9,635.9,316.8,1091.8l733.3,788.1"/>
    </svg>
  </>
}

const Backdrop = () => {
  const style = {
    backgroundColor: 'black',
    opacity: 0.8,
    position: 'fixed',
    zIndex: 999997,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    outline: 0,
    transition: 'opacity 0.15s linear',
  }
  return <div style={style} />
}

const Canvas = ({children, onClick}) => {
  const style = {
    position: 'fixed',
    zIndex: 999998,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
  }
  return <div style={style} onClick={onClick}>{children}</div>
}

const CloseButton = () => {
  return <img
    style={{position: 'absolute', zIndex: 999999, top: 10, right: 10, color: 'white', cursor: 'pointer'}}
    width='30px' height='30px' alt=""
    src="data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJ0aW1lcyIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLXRpbWVzIGZhLXctMTEiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzUyIDUxMiI+PHBhdGggZmlsbD0id2hpdGUiIGQ9Ik0yNDIuNzIgMjU2bDEwMC4wNy0xMDAuMDdjMTIuMjgtMTIuMjggMTIuMjgtMzIuMTkgMC00NC40OGwtMjIuMjQtMjIuMjRjLTEyLjI4LTEyLjI4LTMyLjE5LTEyLjI4LTQ0LjQ4IDBMMTc2IDE4OS4yOCA3NS45MyA4OS4yMWMtMTIuMjgtMTIuMjgtMzIuMTktMTIuMjgtNDQuNDggMEw5LjIxIDExMS40NWMtMTIuMjggMTIuMjgtMTIuMjggMzIuMTkgMCA0NC40OEwxMDkuMjggMjU2IDkuMjEgMzU2LjA3Yy0xMi4yOCAxMi4yOC0xMi4yOCAzMi4xOSAwIDQ0LjQ4bDIyLjI0IDIyLjI0YzEyLjI4IDEyLjI4IDMyLjIgMTIuMjggNDQuNDggMEwxNzYgMzIyLjcybDEwMC4wNyAxMDAuMDdjMTIuMjggMTIuMjggMzIuMiAxMi4yOCA0NC40OCAwbDIyLjI0LTIyLjI0YzEyLjI4LTEyLjI4IDEyLjI4LTMyLjE5IDAtNDQuNDhMMjQyLjcyIDI1NnoiPjwvcGF0aD48L3N2Zz4K"
  />
}
