import { useState } from 'react'
import { ProjectTeaser } from 'betterplace-react-components'
import { GtmHelper } from 'shared'
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

export const ProjectTeaserWithTracking = ({
  index, project, teaserTracking, trackingPath, ...teaserProps
}) => {
  const [tracked, setTracked] = useState(false)

  const handleClick = () => {
    GtmHelper.pushProjectClicks(project, index + 1, trackingPath)
    if (teaserTracking) {
      let expires = new Date()
      expires.setTime(expires.getTime() + (3600 * 1000 * 3))
      cookies.set(`teaser-tracker-p${project.id}`, teaserTracking, { path: '/', expires })
    }
  }

  const handleVisibilityChange = (isVisible) => {
    if (!isVisible || tracked) return
    GtmHelper.pushProjectImpressions(project, index + 1, trackingPath)
    setTracked(true)
  }

  return <VisibilitySensor scrollCheck onChange={handleVisibilityChange}>
    <ProjectTeaser
      project={project}
      textColor='#000000'
      onClick={handleClick}
      {...teaserProps}
    />
  </VisibilitySensor>
}
