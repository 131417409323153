import { useState } from 'react'

export const useModal = () => {
  const [active, setActive] = useState(false)
  const show = (event) => {
    if (event) event.preventDefault()
    setActive(true)
  }
  const hide = (event) => {
    if (event) event.preventDefault()
    setActive(false)
  }
  const background = active ? <div
    className='modal'
    style={{ display: 'block', background: 'black', opacity: 0.1 }}
    onClick={() => setActive(false)}>
  </div> : null

  return { active, background, hide, show }
}
