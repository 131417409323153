import {LogoSpinner} from 'shared'
import classNames from 'classnames'

const loadingStyle = {
  color: 'transparent',
  textShadow: 'none',
  lineHeight: 0,
  transition: 'none',
}

export const Button = (props) => {
  const {loading, className, children, smallSpinner, ...buttonProps} = props

  return <button
    className={classNames('btn', className)}
    disabled={loading} // Can be overriden with buttonProps
    style={loading ? loadingStyle : {}}
    type='submit'
    {...buttonProps}
  >
    {loading && <LogoSpinner smallSpinner />}
    {children}
  </button>
}
