import { useEffect, useState } from 'react'
import { ApiQuery, JSONFetcher, gaEvent } from 'shared'

export const useSearch = ({
  apiBaseUrl,
  bounds = null,
  categoryId = null,
  filter = DefaultFilter,
  query = null
}) => {
  const [state, __setState] = useState({
    loading: true,
    page: 1,
    perPage: 27,
    records: [],
    totalEntries: 0,
    totalPages: 0,
  })
  const [abortController, setAbortController] = useState(null)
  const setState = (newState) => __setState({ ...state, ...newState })

  const perform = (paginate = false) => {
    // cancel any pending search
    abortController?.abort()
    const newAbortController = window['AbortController'] && new AbortController()
    setAbortController(newAbortController)

    setState({ loading: true })

    const apiQuery = new ApiQuery(apiBaseUrl)
    apiQuery.search(query).addBounds(bounds)
      .page(paginate ? state.page + 1 : 1).per(state.perPage)
      .filter(filter)
      .order(DefaultOrder)
      .category(categoryId)

    JSONFetcher.load({
      url: apiQuery.toUrl(),
      signal: newAbortController?.signal,
      success: (res) => setState({
        loading: false,
        page: res.current_page,
        perPage: res.per_page,
        records: paginate ? state.records.concat(res.data) : res.data,
        totalEntries: res.total_entries,
        totalPages: res.total_pages,
      }),
    })
  }

  // call the search API initially and whenever search settings change
  useEffect(perform, [bounds, categoryId, query, filter])

  const loadMore = (event) => {
    event.preventDefault()
    gaEvent('engagement', 'click show_more_projects_link')
    perform(true)
  }
  const hasMore = state.page < state.totalPages

  return { loadMore: hasMore ? loadMore : null, ...state }
}

// prettier-ignore

const DefaultFilter = {
  state:                          'activated',
  min_activity_threshold_reached: true,
  hidden_from_platform:           false
}

const DefaultOrder = {
  category_boost:   'desc',
  score:            'desc',
  completed:        'asc',
  rank:             'desc',
  last_donation_at: 'desc'
}
