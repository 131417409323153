import { useState } from 'react'
import classNames from 'classnames'
import {parseCents, sanitizeMoney} from 'shared'

export const FormCentsInput = ({field, form, hint, ...props}) => {
  const error = form.touched[field.name] && form.errors[field.name]
  const [value, setValue] = useState((form.initialValues)[field.name] / 100)

  return <div className={classNames('form-group', props.groupClass)}>
    <label className='control-label' htmlFor={field.name}>{props.label}</label>

    <div className='input-group'>
      <input
        className={classNames('form-control', {'field-with-error': form.errors.amount_cents})}
        maxLength={8}
        size={8}
        type='text'
        inputMode='decimal'
        value={value}
        onChange={(event) => {
          const cents = parseCents(event.target.value)
          form.setFieldValue(field.name, cents)
          setValue(sanitizeMoney(event.target.value))
        }}
      />
      <div className='input-group-append'>
        <span className='input-group-text'>€</span>
      </div>
    </div>
    {error && <span className='help-inline'>{error}</span>}
    {hint && <small className='form-text text-muted'>{hint}</small>}
  </div>
}
