import { createElement } from 'react'
import { render } from 'react-dom'

export const mountOnId = (component, domId, data = null) => {
  const domNode = document.getElementById(domId)
  if (domNode) {
    const newElement = createElement(component, data || domNode.dataset)
    render(newElement, domNode)
  }
}

export const mountOnDataBehavior = (component, behavior, data = null) => {
  const nodes = document.querySelectorAll(`[data-behavior=${behavior}]`)
  Array.from(nodes).forEach(node => {
    node.removeAttribute('data-behavior')
    const {behavior, ...dataAttributes} = node.dataset
    const tag = createElement(component, data || dataAttributes)
    render(tag, node)
  })
}
