import { useState } from 'react'
import { copyToClipboard, gaEvent } from 'shared'
import classNames from 'classnames'

export const CopyComponent = (props) => {

  const value = decodeURIComponent(props.value)
  const buttonClass = classNames('btn btn-primary', { disabled: props.disabled })
  const ValueTag = props.contentType === 'code' ? 'textarea' : 'input'
  const [copyMode, setCopyMode] = useState(false)

  return <div className='generic-copy-component'>
    <ValueTag readOnly onFocus={e => e.target.select()} value={props.displayValue ? props.displayValue : value} />
    <div className='copy-component-sidebar'>
      <button
        className={`${buttonClass} ${copyMode ? 'in-copy-mode' : ''}`}
        onClick={() => {
          copyToClipboard(value)
          props.gaAction && gaEvent('engagement', props.gaAction, props.gaLabel)
          setCopyMode(true)
          setTimeout(() => setCopyMode(false), 1000)
        }}>
          {copyMode ? I18n.t('misc.generic_copy_alert') : props.ctaCopy}
      </button>
      {props.ctaPreview && <a className={buttonClass} href={props.disabled ? null : props.value} target="_blank">{props.ctaPreview}</a> }
    </div>
  </div>
}
