import { useState } from 'react'
import { Modal, gaEvent } from 'shared'

export const ModalWithTrigger = (props) => {
  const [showModal, setShowModal] = useState(false)

  const hideModal = () => setShowModal(false)
  const openModal = () => {
    setShowModal(true)
    if (props.clicktrackingAction) {
      gaEvent('engagement', props.clicktrackingAction)
    }
  }

  return (
    <>
      {props.buttonTriggered ?
        <button className={props.cssClass || 'btn btn-large btn-danger'} onClick={openModal}>
          {props.trigger}
        </button> :
        <span className={props.cssClass || 'simulated-link'} onClick={openModal}>
          {props.trigger}
        </span>
      }

      <Modal
        onHide={hideModal}
        show={showModal}
        header={props.title}
      >
        <div dangerouslySetInnerHTML={{__html: props.content}} />
        {props.children}
      </Modal>
    </>
  )
}
