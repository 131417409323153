import { Swiper as SwiperJs, SwiperSlide as SwiperJsSlide } from 'swiper/react'
import SwiperCore, { Pagination, Mousewheel, A11y } from 'swiper'

SwiperCore.use([Pagination, Mousewheel, A11y])

export const Swiper = props => {
  const slides = JSON.parse(props.slides)

  return (
    <div className='relative'>
      {props.pagination === 'arrow-buttons'}
      <div className='generic-teaser-swiper user-select-none'>
        <SwiperJs
          className='pb-0'
          slidesPerView={1}
          pagination={slides.length > 1 ? { clickable: true } : false}
          roundLengths
          mousewheel={{ forceToAxis: true }}
          breakpoints={{
            451: {
              slidesPerView: 3
            }
          }}
        >
          {slides.map((slideId, index) => (
            <SwiperJsSlide key={slideId}>
              <div ref={ref => ref && ref.appendChild(document.getElementById(slideId).cloneNode(true))} />
            </SwiperJsSlide>
          ))}
        </SwiperJs>
      </div>
    </div>
  )
}
