export const Chevron = ({ direction, id }) => {
  const transform = direction === "left" ? "translate(2.137 2.121)" : "translate(32.121 61.245) rotate(180)"
  const xPosition = direction === "left" ? { left: -80 } : { right: -80 }

  return <div id={id} className='generic-chevron hidden desktop:block' style={xPosition}>
    <svg width="34.258" height="63.366" viewBox="0 0 34.258 63.366">
      <path transform={transform} d="M30,59.124,0,29.277,30,0" fill="none" stroke="#4597A2" strokeLinecap="round" strokeWidth="3" />
    </svg>
  </div>
}
