export class QueryHelper {
  static parseQuery() {
    var query = {}
    for (let queryPart of window.location.search.substr(1).split('&')) {
      let [key, value] = queryPart.split('=')
      query[key] = value
    }
    return query
  }

  static toQuery(object) {
    const kvPairs = Object.keys(object).map(key => {
      const value = object[key]
      if (value === undefined || value === null || value === '') return null
      return `${key}=${value}`
    }).filter(e => e)
    if (kvPairs.length === 0) return ''
    return `?${kvPairs.join('&')}`
  }
}
