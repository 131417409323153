import {formatCents, parseCents} from 'shared'

export const CentsInput = (props) => {
  const handleChange = (event) => {
    props.control.value = parseCents(event.target.value)
  }

  return <div className='input-group'>
    <input style={{zIndex: 1}} type='text' onChange={handleChange} defaultValue={formatCents(props.initialValue, {format: '%v'})} />
    <div className='input-group-append'>
      <span className='input-group-text'>€</span>
    </div>
  </div>
}
