import { useState } from 'react'

export const PasswordReveal = (props) => {
  const [type, setType] = useState('password')
  const input = document.getElementById(props.inputId)
  input.type = type


  return <span
    className={`password-reveal-icon fa fa-${type == 'text' ? 'eye-slash' : 'eye'}`}
    onClick={ () => setType(type == 'text' ? 'password' : 'text') }
  />

}
