export const quote = (str) => I18n.locale === 'de' ? `„${str}“` : `“${str}“`

export const truncate = (text, { length }) => {
  return (text.length > length) ? `${text.substring(0, length)}…` : text
}

export const toSentence = (...words) => {
  const and = I18n.locale === 'de' ? 'und' : 'and'

  switch (words.length) {
    case 0: return ``
    case 1: return `${words[0]}`
    case 2: return `${words[0]} ${and} ${words[1]}`
    default: return `${words.slice(0, -1).join(', ')} ${and} ${[...words].pop()}`
  }
}

export const formatNumber = (number, { precision } = {}) => {
  const options = {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  }
  return Intl.NumberFormat(I18n.locale, options).format(number)
}
