import { useState, useEffect } from 'react'
import { FacebookButton, TwitterButton, EmailButton, WhatsappButton, FacebookMessengerButton, LinkButton, InstagramButton } from 'betterplace-react-components'
import { gaEvent } from 'shared'
import { CSSTransition } from 'react-transition-group'
import { useWindowSize } from 'react-use'

export const ShareButtons = (props) => {
  if (props.status == 'blocked' || props.status == 'preparing') return null

  const [copyMode, setCopyMode] = useState(false)
  const [copyModeInstagram, setCopyModeInstagram] = useState(false)
  const [left, setLeft] = useState(0)

  const handleResize = () => {
    let bodyLeft = document.querySelector('body').getBoundingClientRect().left
    if (bodyLeft < 0) bodyLeft = 0
    setLeft(bodyLeft)
  }
  const dim = useWindowSize()
  useEffect(handleResize, [dim])

  const scope = props.fundraisingEvent ? 'fundraising_events.show.sharing' : 'projects.show.share_buttons'

  const buttonProps = (platform, callback) => ({
    className: props.mobileOnly?.includes(platform) ? 'mobile-only-flex' : '',
    beforeOnClick:   () => {
      gaEvent('engagement', `click ${platform}_social_sharing_button`, props.gaLabel)
      callback && callback()
    },
    content:         I18n.t(`${platform}_share_label`, {scope}),
    shape:           props.shape,
    shareUrl:        props.shareUrl,
    teaser:          I18n.t(`${platform}_teaser`, {scope, defaultValue: I18n.t('default_teaser', {scope})}),
    title:           I18n.t(`${platform}_share_label`, {scope}),
    withLabel:       props.withLabel,
    utmParams:       {
      utm_campaign: 'user_share',
      utm_medium:   `${props.gaLabel}`,
      utm_source:   platform.split('_').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' '),
    },
  })

  const { className, children, platforms } = props
  const facebookAppId = document.head.querySelector("[property='fb:app_id']")?.content || null

  return <>
    <CSSTransition in={copyMode} timeout={200} className='copy-overlay' unmountOnExit style={{ left: left, top: (props.project ? 'auto' : 0), bottom: (props.project ? 0 : 'auto') }}>
      <div>
        <strong>{I18n.t('misc.copy_alert.headline')}</strong> {copyModeInstagram && <><span>&nbsp;</span><span>{I18n.t('misc.copy_alert.text')}</span></>}
      </div>
    </CSSTransition>
    <ul className={ `generic-social-sharing list-unstyled ${className || ''}` }>
      {children}

      {platforms.includes('facebook')  && <FacebookButton {...buttonProps('facebook')} />}
      {platforms.includes('twitter')   && <TwitterButton {...buttonProps('twitter')}/>}
      {platforms.includes('email') && <EmailButton {...buttonProps('email')} color='#2B8475'/>}
      {platforms.includes('link') && <LinkButton {...buttonProps('link', () => {
        setCopyMode(true)
        setCopyModeInstagram(false)
        setTimeout(() => setCopyMode(false), 2400)
      })} />}
      {platforms.includes('whatsapp')  && <WhatsappButton {...buttonProps('whatsapp')}/>}
      {platforms.includes('messenger') && <FacebookMessengerButton {...buttonProps('messenger')} facebookAppId={facebookAppId}/>}
      {platforms.includes('instagram') && <InstagramButton {...buttonProps('instagram', () => {
        setCopyModeInstagram(true)
        setCopyMode(true)
        setTimeout(() => setCopyMode(false), 4000)
      })} />}
    </ul>
  </>
}
