import { OverlayTrigger, Popover as RBPopover } from 'react-bootstrap'

export const Popover = ({placement, popover, trigger, children}) => {
  const popoverContent = popover ? <div dangerouslySetInnerHTML={{__html: popover}} /> : children

  return <OverlayTrigger
    trigger={['hover', 'focus']}
    placement={placement}
    flip
    popperConfig={{
      placement: placement,
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'bottom'],
          },
        },
      ],
    }}
    overlay={<RBPopover><RBPopover.Content>{popoverContent}</RBPopover.Content></RBPopover>}
    // rootClose - don’t use since it does not work on native iOS devices
  >
    {trigger}
  </OverlayTrigger>
}

export const PopoverIcon = ({
  icon = '', // allows 'info', 'hint', 'delete'
  ...props
}) => {
  return <Popover
    trigger={<span className={`generic-popover-icon ${icon}`} />}
    {...props}
  />
}

export const PopoverText = ({
  trigger,
  ...props
}) => {
  return <Popover
    trigger={<span>{trigger}</span>}
    {...props}
  />
}
