import { ProjectPreviewList, LogoSpinner, Button } from 'shared'

export const RecordList = ({
  records, render, fancyButton = false, loading = false, loadMore = null, perPage = null
}) => {
  return <div className='row'>
    {records?.map((record, index) =>
      <div className="col-md-8 mb-4" key={index}>
        {render(record, index)}
      </div>
    )}

    {records?.length > 0 || (loading ? <ProjectPreviewList count={perPage} /> : <NoResult />)}

    <LoadMoreButton fancy={fancyButton} loading={loading} loadMore={loadMore} />
  </div>
}

const LoadMoreButton = ({ fancy = false, loading, loadMore }) => {
  const label = I18n.t('discover.categories.index.moar')
  if (!loadMore) return null

  let button = null
  if (fancy) button = <Button className='btn-large btn-primary' smallSpinner loading={loading} onClick={loadMore}>{label}</Button>
  else if (loading) button = <LogoSpinner color='#9ECB0A' />
  else button = <button className='simulated-link' onClick={loadMore}>{label}</button>

  return <div className='col-md-24 text-center'>{button}</div>
}

const NoResult = () =>
  <div className='generic-no-results'>
    {I18n.t('discover.categories.index.no_result_hint', { defaultValue: 'N/A' })}
  </div>
