import { useState, useEffect } from 'react'
import { useTween } from 'react-use'
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor'
import { formatCents, formatNumber } from 'shared'

/**
 * Markup Example:
 * <span data-behavior="counter-widget"
 *       data-count-to-url="/de/fundraising-events/counters/xmas_streams"
 *       data-count-from="3.142424242"
 *       data-count-duration="5"
 * />
 */

/**
 * Provides animated counting from one number to another.
 *
 * @param {Object} props:
 *        countTo:         Where to stop counting.
 *        countToUrl:      URL to fetch the count-to value from.
 *        countFrom:       Where to start counting.
 *        countDuration:   Duration of the counting animation in seconds.
 *        countDelay:      Time in seconds to wait before starting the animation after pageload.
 *        countEasing:     Easing function for the animation. https://github.com/streamich/ts-easing/blob/master/src/index.ts
 *        formatCurrency:  Format the value as cents of the given currency.
 *        formatPrecision: Number of fraction digits.
 */
export const CounterWidget = (props) => {
  const [visible, setVisible] = useState(false)

  return <VisibilitySensor scrollCheck onChange={value => { visible || setVisible(value) }}>
    {/* Keep the placeholder! Without it the box has zero size and never becomes visible. */}
    <span>{visible ? <Counter {...props} /> : ' '}</span>
  </VisibilitySensor>
}

const Counter = (props) => {
  const [countTo, setCountTo] = useState(+props.countTo)

  const countFrom = +(props.countFrom || 0)
  const countDuration = 1000 * (props.countDuration || 3)
  const countEasing = props.countEasing || 'inOutCubic'
  const countDelay = 1000 * (props.countDelay || 0)

  useEffect(
    () => {
      if (props.countToUrl) {
        fetch(props.countToUrl).then(response => {
          response.text().then(text => setCountTo(+text))
        })
      }
    },
    []
  )

  const tween = useTween(countEasing, countDuration, countDelay)

  return <>{format(countFrom + tween * (countTo - countFrom), props.formatPrecision, props.formatCurrency)}</>
}

const format = (number, precision, currencySymbol) => {
  if (precision !== undefined) precision = +precision
  if (currencySymbol) {
    const options = {
      symbol: currencySymbol,
      precision: precision,
    }
    return formatCents(number, options)
  } else {
    return formatNumber(number, { precision: precision || 0 })
  }
}
