import { useState, useEffect } from 'react'

export const WWLogoWhitener = ({url}) => {
  const [data, setData] = useState(null)
  useEffect(() => {
    fetch(url)
    .then(response => response.text())
    .then(text => {
      setData(text.replace(/#ff0000/gi, '#ffffff'))
    })
    .catch(console.warn)
  }, [])

  if (data === null) return null
  return <div dangerouslySetInnerHTML={{__html: data}} ></div>
}
