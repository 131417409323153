import { useEffect, useState } from 'react'
import classNames from 'classnames'
import TextareaAutosize from 'react-autosize-textarea'
import shortAndSweet from 'short-and-sweet'

export const FormTextArea = ({field, form: {touched, errors}, hint, ...props}) => {
  const hasError = touched[field.name] && errors[field.name]
  const classes = classNames(props.className, 'form-control', {'field-with-error': hasError})
  const [loadingComplete, setLoadingComplete] = useState(false)

  useEffect(() => {
    shortAndSweet(`#${field.name}[maxlength]`, {
      counterClassName: 'module-text-counter',
      counterLabel: '{length} / {maxlength}',
      append: (el, counter) => {el.parentNode.insertBefore(counter, el.nextSibling)}
    })
    setLoadingComplete(true) // force text area autosize to rerender
  }, [])

  return <div className={classNames('form-group', props.groupClass)}>
    <label className='control-label' htmlFor={field.name}>{props.label}</label>
    <TextareaAutosize id={field.name} className={classes} {...field} {...props} loadingcomplete={loadingComplete.toString()} />
    {hasError && <span className='help-inline'>{errors[field.name]}</span>}
    {hint && <small className='form-text text-muted'>{hint}</small>}
  </div>
}
