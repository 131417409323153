import { useJsonApi } from 'shared'

// Load a project from a given api_v4 URL with sensible preview data and status mixed in
export const useProject = (apiUrl) => {
  const { data, loading } = useJsonApi(apiUrl)
  return { ...defaults, ...data, loading, status: projectStatus(data, loading) }
}

const defaults = {
  donations_count: 0,
  comments_count: 0,
  blog_post_count: 0,
  progress_percentage: 0,
  open_amount_in_cents: 0,
}

// display state calculator, use with project result from api_v4
export const projectStatus = (project, loading = false) => {
  if (loading) return 'activated' // optimistically show activated state UI
  else if (project.completed_at) return 'completed'
  else if (project.closed_at) return 'closed'
  else if (project.activated_at) return project.donations_prohibited ? 'blocked' : 'activated'
  else return 'preparing'
}
