import RBModal from 'react-bootstrap/Modal'

export const Modal = (props) => {
  return <RBModal show={props.show} onHide={props.onHide} dialogClassName={props.size || ''}>
    <RBModal.Header>
      <RBModal.Title>
        <p className='m-0'>
          {props.header}
        </p>
        {props.headerSubline && <h5>{props.headerSubline}</h5>}
      </RBModal.Title>
      <button type="button" className="modal-close" aria-label="Close" onClick={props.onHide}></button>
    </RBModal.Header>

    <RBModal.Body>
      {props.content}
      {props.children}
    </RBModal.Body>

    { props.footer && <RBModal.Footer>
        {props.footer}
      </RBModal.Footer>
    }
  </RBModal>
}
